var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"slots-list"},[_c('div',{staticClass:"flex flex-wrap -mx-1"},_vm._l((Object.entries(_vm.weekDaysFrontend)),function(ref,index){
var day = ref[0];
var dayValue = ref[1];
return _c('div',{key:index,staticClass:"w-full xs:w-1/2 md:w-2/6 xl:w-1/7 p-1"},[_c('a-card',{staticClass:"slot_card",attrs:{"size":"small","title":_vm.weekDayTitles[day]}},[(dayValue.slots.length)?_c('ul',_vm._l((dayValue.slots.sort(function (a, b) { return _vm.moment(a.time, 'HH:mm').valueOf() - _vm.moment(b.time, 'HH:mm').valueOf(); })),function(slot,index){return _c('li',{key:index,staticClass:"slot__item mb-2 last:mb-0"},[_c('div',{staticClass:"slot__body flex justify-between"},[_c('span',{staticClass:"slot__time cursor-pointer hover:opacity-75",on:{"click":function($event){return _vm.editSlot(day, slot)}}},[_vm._v(" "+_vm._s(_vm.formatSlotTime(slot.time))+" ")]),_c('div',{staticClass:"slot__actions"},[_c('a-tooltip',{attrs:{"title":_vm.$t('ad_posts_only')}},[(slot.ads_only)?_c('a-icon',{staticClass:"text-success cursor-pointer mr-1",attrs:{"type":"dollar"}}):_vm._e()],1),_c('a-icon',{staticClass:"text-danger hover:opacity-75",attrs:{"type":"delete"},on:{"click":function($event){return _vm.onRemoveSlot(slot.index)}}})],1)])])}),0):_c('empty-data'),_c('template',{slot:"actions"},[_c('a-icon',{staticClass:"w-full btn-wrap",attrs:{"type":"plus"},on:{"click":function($event){return _vm.openAddSlotModal(day)}}})],1)],2)],1)}),0),_c('a-modal',{attrs:{"centered":"","title":_vm.modalTitle,"ok-text":_vm.$t('save'),"ok-button-props":{
      props: {
        disabled: !_vm.slotModel.time
      }
    },"cancel-text":_vm.$t('cancel'),"after-close":_vm.handleModalCancel},on:{"ok":_vm.handleOkButtonClick},model:{value:(_vm.addSlotModalVisible),callback:function ($$v) {_vm.addSlotModalVisible=$$v},expression:"addSlotModalVisible"}},[_c('time-picker-input',{attrs:{"setup":{
        'func': _vm.modelSetter,
        'args': {
          'model': _vm.slotModel,
          'key': 'time',
          'clearable': false,
          'time24hr': true,
        }
      }}}),(!_vm.adsOnly)?_c('switch-input',{attrs:{"setup":{
        'func': _vm.modelSetter,
        'args': {
          'model': _vm.slotModel,
          'key': 'ads_only',
        }
      }}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }