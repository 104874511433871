import { defaultStyleModel } from '@/includes/logic/Style/constant'
import { Style, StyleFields } from '@/includes/types/Post.types'
import { BaseItemViewAction } from '@/includes/types/BaseItemViewAction'
import { Slot } from '@/includes/types/Board.types'
import { Advert, FrontendAdvert } from '@/includes/logic/Offers/types'
import store from '@/store/store'
import router from '@/router'

import { IterableItemFactory, IterableListItem } from "piramis-base-components/src/shared/utils/IterableItemFactory";

import { cloneDeep } from 'lodash'

export const TWO_DAYS = 172800
let currentAdIndex: number | null = null;
const iterableItemFactory = new IterableItemFactory()

export const adStyleFields: StyleFields = [
    "id",
    'postfix_message',
    'prefix_message',
    'watermark_data',
    'watermark_type',
    'watermark_position',
    'protect_content'
]

export function getFrontendAdverts(raw: Array<Advert>) {
    return raw.map(o => iterableItemFactory.create(o))
}

export function getRawAdverts(items: Array<IterableListItem<Advert>>) {
    return items.map(i => i.value)
}

// set fields that can`t be set in offer to it empty (default) values (ex: actions)
function prepareStyle(modelCopy: FrontendAdvert) {
    const defaultSettings = cloneDeep(defaultStyleModel)

    Object.keys(modelCopy.style!).forEach((key) => {
        const fieldName = key as keyof Partial<Style>

        if (!adStyleFields.includes(fieldName)) {
            if (modelCopy.style) {
                modelCopy.style[fieldName] = defaultSettings[fieldName]
            }
        }
    })

    if (modelCopy.style?.data) {
        modelCopy.style.data = null
    }
}

export function prepareModelToSave(offer: FrontendAdvert) {
    return new Promise<Advert>(resolve => {
        const modelCopy = cloneDeep(offer)

        if (modelCopy.expire !== undefined && modelCopy.expire <= TWO_DAYS) {
            modelCopy.replace_message = null
        }

        if (modelCopy.style !== null) {
            prepareStyle(modelCopy)
        }

        resolve(modelCopy as Advert)
    })
}

export function findOfferByIndex(index: number) {
    return store.state.boardsState.activeBoard!.config.ads.at(index - 1)
}

export function removeSlot(offer: FrontendAdvert, slotIndex: number) {
    const offerCopy = { ...offer }

    offerCopy.plan.splice(slotIndex, 1)

    return offerCopy
}

export function editSlot(offer: FrontendAdvert, payload: Slot & { index?: number }) {
    const offerCopy = { ...offer }
    const payloadCopy = { ...payload }

    delete payloadCopy.index

    offerCopy.plan.splice(payload.index!, 1, payloadCopy)

    return offerCopy
}

export function saveOffer(offer: FrontendAdvert, actionType: BaseItemViewAction) {
    prepareModelToSave(offer)
        .then(res => {
            store.commit('pi/EXEC', {
                'fn': () => {
                    const { activeBoard } = store.state.boardsState

                    if (activeBoard) {
                        if (actionType === BaseItemViewAction.Edit) {
                            if (currentAdIndex) {
                                activeBoard.config.ads.splice(currentAdIndex - 1, 1, res)
                            }
                        } else {
                            if (activeBoard.config.ads === null) {
                                activeBoard.config.ads = []
                            }

                            activeBoard.config.ads.push(res)
                        }
                    }
                },
            })
        })
        .then(() => {
            store.dispatch('savePostBoardConfig')
                .then(() => {
                    router.push({ name: 'OffersList' })
                })
        })
}

export function getOfferTemplate(): FrontendAdvert {
    return {
        name: '',
        targets: [],
        rules: '',
        cash_register_id: null,
        product_id: null,
        plan: [],
        expire: 86400,
        top: 7200,
        pin: 0,
        moderation_timeout: 86400,
        pay_timeout: 3600,
        style: null
    }
}

export function init(index?: number) {
    if (index) {
        currentAdIndex = index

        return findOfferByIndex(index) ?? null
    } else {
        return getOfferTemplate()
    }
}
