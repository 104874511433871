































































































type WeekDaysFrontend = Record<number, {
  slots: Array<Slot & { index: number }>
}>

import { Slot } from '@/includes/types/Board.types'
import { errorNotification } from '@/includes/services/NotificationService'
import { InputSetups } from '@/mixins/input-setups'

import EmptyData from 'piramis-base-components/src/components/EmptyData/EmptyData.vue'
import { UseFields } from 'piramis-base-components/src/components/Pi'

import { Component, Emit, Mixins, Prop } from 'vue-property-decorator'
import moment from 'moment'

@Component({
  components: {
    EmptyData
  },
  data() {
    return {
      moment
    }
  }
})
export default class SlotsList extends Mixins<UseFields, InputSetups>(UseFields, InputSetups) {
  @Prop() slots!:any

  @Prop({ type: Boolean, default: false }) adsOnly!:boolean

  @Emit()
  onAddSlot(items) {
    return items
  }

  @Emit()
  onEditSlot(items) {
    return items
  }

  @Emit()
  onRemoveSlot(index) {
    return index
  }

  addSlotModalVisible = false

  activeWeekDay: number | null = null

  slotModel = this.newSlotFabric

  slotModalType: 'new' | 'edit' = 'new'

  weekDayTitles = {
    1: this.$t('monday').toString(),
    2: this.$t('tuesday').toString(),
    3: this.$t('wednesday').toString(),
    4: this.$t('thursday').toString(),
    5: this.$t('friday').toString(),
    6: this.$t('saturday').toString(),
    7: this.$t('sunday').toString(),
  }

  get weekDaysFrontend(): WeekDaysFrontend {
    const weekDaysFrontend: WeekDaysFrontend = {
      1: { slots: [] },
      2: { slots: [] },
      3: { slots: [] },
      4: { slots: [] },
      5: { slots: [] },
      6: { slots: [] },
      7: { slots: [] }
    }

    this.slots.forEach((value, index) => {
      weekDaysFrontend[value.active_day_of_week].slots.push({ ...value, index })
    })

    return weekDaysFrontend
  }

  get newSlotFabric(): Slot & { index?: number } {
    return {
      time: '',
      ads_only: this.adsOnly,
      allow_styles: [],
      active_day_of_week: 1
    }
  }

  get modalTitle(): string {
    if (this.slotModalType === 'new') return this.$t('slot_modal_create').toString()
    if (this.slotModalType === 'edit') return this.$t('slot_modal_edit').toString()
    throw new Error('Unknown slotModalType value')
  }

  formatSlotTime(time: string): string {
    return moment(time, 'HH:mm').format('HH:mm')
  }

  editSlot(day: string, slot: Slot): void {
    this.slotModalType = 'edit'
    this.slotModel = { ...slot }
    this.addSlotModalVisible = true
  }

  editExistedSlot(slot: Slot & { index?: number }): void {
    this.onEditSlot(slot)
    this.addSlotModalVisible = false
  }

  slotExists(slot: Slot): boolean {
    return this.slots.some(s => s.time === slot.time && s.active_day_of_week === slot.active_day_of_week)
  }

  handleOkButtonClick(): void {
    if (this.slotModalType === 'new') {
      this.handleAddSlotButton()
    } else {
      if (this.slotModel.index !== undefined) {
        const slotToEdit: Slot = this.slots[this.slotModel.index]

        if (slotToEdit.time === this.slotModel.time) {
          this.editExistedSlot(this.slotModel)
        } else {
          if (!this.slotExists(this.slotModel)) {
            this.editExistedSlot(this.slotModel)
          } else {
            errorNotification(this.$t('slot_exists_error').toString())
          }
        }
      }
    }
  }

  openAddSlotModal(active_day_of_week: string): void {
    this.slotModalType = 'new'
    this.activeWeekDay = Number(active_day_of_week)
    this.addSlotModalVisible = true
  }

  handleModalCancel(): void {
    this.slotModel = { ...this.newSlotFabric }
    this.activeWeekDay = null
  }

  handleAddSlotButton(): void {
    if (this.activeWeekDay) {

      const resObject: Slot = {
        ...this.slotModel,
        active_day_of_week: this.activeWeekDay
      }

      if (!this.slotExists(resObject)) {
        this.onAddSlot(resObject)
        this.addSlotModalVisible = false
      } else {
        errorNotification(this.$t('slot_exists_error').toString())
      }
    } else {
      errorNotification('__Active weekday is null')
    }
  }
}
